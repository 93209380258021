import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { graphql } from "gatsby"
// import Hero from "../components/hero/index"
import { StaticImage } from "gatsby-plugin-image"
import longvityimg from "../images/home2/healthy-longevity.jpg"

const theWhy = ({ data, location }) => {
  // const page = data && data.page

  return (
    <Layout location={location}>
      <SEO title="About NuQ" />
      {/* <div className="page-headline">
        <div className="container">
          <div className="section-heading text-center">
            <h6 className="font-weight-bold text-uppercase text-white-50 flair">
              {page.title}
            </h6>
          </div>
        </div>
      </div> */}
      <div className="page-content about-page">
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-6 d-inline align-items-center mx-auto">
              <div className="about-content">
                <div className="page-content-title text-left d-flex alight-items-center justify-content-start">
                  <h2 className="section-title text-d-cyan mr-4">
                    The <span className="title-underline">Why</span>
                  </h2>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="148.679"
                    height="161.784"
                    viewBox="0 0 148.679 161.784"
                    className="about-nuq-svg"
                  >
                    <g
                      id="Group_72869"
                      data-name="Group 72869"
                      transform="translate(-196.991 -290.005)"
                    >
                      <g id="Group_73507" data-name="Group 73507">
                        <path
                          id="Path_37282"
                          data-name="Path 37282"
                          d="M74.768,110.623c-15.446,0-29.252-6.793-39.922-19.649C18.333,71.091,10.551,37.87,15.054,6.341a101.62,101.62,0,0,1,4.684-19.558c8.471-24.112,28.927-37.944,56.1-37.944a82.6,82.6,0,0,1,9.915.612,97.574,97.574,0,0,1,52.74,24.385A79.909,79.909,0,0,1,152.543-9.43C172.5,22.646,161.99,59.718,124.41,89.764c-14.873,11.906-29.356,18.777-43.058,20.456A54.328,54.328,0,0,1,74.768,110.623Z"
                          transform="translate(183.198 341.166)"
                          fill="#01e1ca"
                        />
                        <text
                          id="SALMONWITH"
                          transform="translate(211 377.522)"
                          fill="#fff"
                          font-size="38"
                          font-family="Rubik-Medium, Rubik"
                          font-weight="500"
                        >
                          <tspan x="0" y="0">
                            NuQ™
                          </tspan>
                        </text>
                      </g>
                    </g>
                  </svg>
                </div>
                {/* <p className="text-d-green-darker text-20"> */}
                <h1>Healthy Longevity</h1>
                <p className="text-d-green-darker" style={{ fontSize: "22px" }}>
                  During the past several years the Health and Wellness market
                  has gone into overdrive. Every day there seems to be a new
                  diet, method, strategy each proclaiming their respective
                  virtues and the eternal promise of ‘losing those extra pounds
                  quickly to look and feel your best’. This is nothing more than
                  a modern day snake oil salesmen (and women), using the same
                  old con but with a new spin.
                </p>
                <p className="text-d-green-darker" style={{ fontSize: "22px" }}>
                  However, <a href="https://bit.ly/3esvlcv">Longevity</a>, a
                  subject <b>NuQ</b> tackled a while ago, is having its hockey
                  stick moment. Indeed, we all want to live longer but living
                  longer AND healthier is not a guaranteed outcome. As they say,
                  old age creeps up slowly after birth and then, one day, we are
                  there. The road to{" "}
                  <b>
                    {" "}
                    <span className="text-underline mx-1">
                      Healthy Longevity
                    </span>
                  </b>{" "}
                  needs a MapQuest based on science, not potions or gimmicks.
                </p>
                <p className="text-d-green-darker" style={{ fontSize: "22px" }}>
                  That is why we created Nutrient Quotient (<b>NuQ</b>), a
                  science-based methodology converted into a simple tool to help
                  consumers track their nutrient consumption and personalized
                  needs including macro nutrients, vitamins and minerals. To us,
                  it is not only important what micronutrients we are consuming
                  but also equally important, what micronutrients are missing
                  from our diet and we are NOT consuming.
                </p>
                <b className="text-d-green-darker" style={{ fontSize: "22px" }}>
                  The best way, perhaps the only way to fix our irrevocably
                  broken healthcare system is to NEVER depend on it!
                </b>
              </div>
            </div>
            <div className="col-lg-6 mx-auto pt-5 mt-4">
              <div className="text-center">
                <div className="row">
                  <div className="col-md-12 pt-5">
                    <h1><a href="https://longevity.technology/lifestyle/longevity-researcher-says-hes-a-decade-younger-thanks-to-four-habits-he-practices-daily/" target="_blank" rel="noopener noreferrer">Nutrition is the key to longevity </a>– Dr. David Sinclair</h1>
                    <img src={longvityimg} className="img-fluid w-100 px-5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default theWhy
